<template>
  <svg
    class="your-svg-icon"
    enable-background="new 0 0 48 48"
    height="48px"
    id="Layer_3"
    version="1.1"
    viewBox="0 0 48 48"
    width="25px"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <polygon
        points="39.979,8.759 35.75,8.759 32.875,5.697 30.875,48.01 45,44.885  "
      />
      <path
        d="M30.229,6.192c-0.159-0.889-0.485-1.646-0.893-2.295c-0.012-0.021-0.019-0.038-0.03-0.061   c-0.93-1.782-2.419-2.309-2.864-2.432c-0.009-0.002-0.017-0.005-0.024-0.008c-0.069-0.018-0.116-0.029-0.116-0.029l0.01,0.012   c-0.38-0.087-0.776-0.113-1.185-0.066c-0.595-0.697-1.414-1.201-2.542-1.304c-4.542-0.417-8.125,6.167-8.125,6.167   s-1.595,3.577-1.902,5.539l-5.744,1.795L3,43.01l26.812,5l2-42.313L30.229,6.192z M24.667,4.343   c0.292,2.708,0.226,3.516,0.226,3.516l-4.828,1.509c0,0,0.435-3.274,2.727-5.066c0.613-0.479,1.168-0.776,1.667-0.952   C24.553,3.638,24.626,3.964,24.667,4.343z M14.53,11.097c0,0,0.512-3.004,1.887-5.004s2.875-3.75,5.333-4.5   C22.141,1.49,22.784,1.5,23.372,1.86c-0.924,0.458-1.832,1.163-2.622,2.024c-2.075,2.261-2.498,6.05-2.498,6.05h0L14.53,11.097   L14.53,11.097z M24.721,18.938l-1.408,3.634c0,0-3.312-1.438-5.188-0.438s-1.062,3.188,0,3.813s3.5,2.25,4.25,3.438   c0.53,0.84,0.864,2.254,0.976,3.377h0.001c0,0,0.897,4.873-4.04,7.561s-9.688-1.812-9.938-2.5c0.75-2.062,1.375-4.062,1.375-4.062   s2.625,2.062,4.312,2s2.555-1.476,1.94-2.562c-0.045-0.08-0.071-0.166-0.087-0.254c-0.168-0.394-0.676-1.235-2.229-2.434   c-2.188-1.688-3.031-4.334-2.737-6.066c0.003-0.017,0.011-0.036,0.014-0.052c0.193-2.832,1.784-5.19,4.66-6.882   c3.188-1.875,8.812-0.312,8.812-0.312L24.721,18.938z M26.668,7.305L26.668,7.305c0,0,0.142-2.156-0.528-4.126   c0.203,0.035,0.373,0.094,0.535,0.154c0.295,0.133,1.003,0.533,1.412,1.446c0.034,0.077,0.066,0.141,0.097,0.196   c0.383,0.801,0.686,1.642,0.686,1.642L26.668,7.305z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ShopifyIcon"
};
</script>

<style scoped>
.your-svg-icon {
  fill: currentColor;
}
</style>
