import axios from "axios";
import Vue from "vue";

export const API_ORIGIN = localStorage.getItem('VUE_APP_CORE_SERVER_ORIGIN');
export const BASE_API_URL = API_ORIGIN + "/api/v1";

// Authorization header is inserted automatically to each request (if token is present)
export const authInterceptor = [
  config => {
    if (Vue.prototype.$token()) {
      config.headers.Authorization = `Bearer ${Vue.prototype.$token()}`;
    }
    return config;
  },
  error => Promise.reject(error)
];

export const Service = url =>
  axios.create({
    baseURL: BASE_API_URL + url,
    params: { json: true }
  });

export const AuthorizedService = url => {
  const authorizedService = Service(url);
  authorizedService.interceptors.request.use(...authInterceptor);
  return authorizedService;
};
