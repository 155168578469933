import axios from "axios";

import { authInterceptor, BASE_API_URL, API_ORIGIN } from "./index";

export const authorizedReq = axios.create({
  params: { json: true }
});
authorizedReq.interceptors.request.use(...authInterceptor);

export const applyCommonActionWithUri = uri =>
  authorizedReq({
    method: "GET",
    url: `${BASE_API_URL}/${uri}`
  });

export const getUserDetails = () =>
  authorizedReq.get(BASE_API_URL + "/user/details");

export const getMerchantConfig = () => axios.get(`${BASE_API_URL}/config/`);

export const loadTranslations = () =>
  axios.get(`${API_ORIGIN}/json-i18n?json=true`);
