import Vue from "vue";
import VueRouter from "vue-router";

import { i18n } from "./../plugins/i18n";
Vue.use(VueRouter);
const routes = [
  {
    path: "/:lang/",
    exact: true,
    redirect: `/${i18n.locale}/dashboard`
  },
  {
    path: "/:lang/login",
    exact: true,
    redirect: `/${i18n.locale}/dashboard`
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
