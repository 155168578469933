<template>
  <vue-app>
    <Dashboard v-if="!noNavBar" />
    <SnackBar />
  </vue-app>
</template>

<script>
import Vue from "vue";

import Dashboard from "./components/Dashboard.vue";

import SnackBar from "@/components/SnackBar";

export default {
  components: { Dashboard, SnackBar },
  computed: {
    noNavBar() {
      return Vue.prototype.$noNavBar;
    }
  }
};
</script>
